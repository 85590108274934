.home .content {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 20px;
    min-height: 100vh;
}
.home section:not(.contactme):not(.gallery) {
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* min-height: 100vh; */
    color: #fff;
}
.home .banner {
    margin: 0 -20px;
    width: calc(100% + 40px);
}
.home .banner1 {
    background-image: url('../assets/images/home-banner-mob.jpg');
}
.home .banner2 {
    background-image: url('../assets/images/home-banner2-mob.jpg');
}
.home .banner::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: black;
    opacity: 0.25;
}
.banner2 .content h2 {
    font-size: 32px;
    text-align: center;
    margin-bottom: 48px;
}
.message {
    max-width: 768px;
    margin: 0 auto;
}
.message p {
    font-weight: 100;
    margin: 24px 0;
    font-size: 18px;
}
.watermark svg path {
    fill: #fff;
}
.watermark svg {
    width: 160px;
    margin-bottom: 24px;
    margin: 14px auto;
    display: block;
}

.scroll svg {
    max-width: 28px;
    width: 100%;
}
.scroll {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    padding-bottom: 48px;
    cursor: pointer;
    -webkit-animation-iteration-count: 5;
    animation-iteration-count: 5;
    animation-delay: 3.5s;
}
.signature {
    position: relative;
    align-self: center;
    font-size: 40px;
    font-family: 'salesforce';
    font-weight: 100;
    color: #fff;
    margin: 40px;
    padding-bottom: 50%;
    transition: 200ms all ease-in-out;
    opacity: 0.8;
}
.signature svg {
    width: 100%;
    height: 100%;
}
.home-gallery > div, .contact-me > div {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
}
.contact-me .contact {
    margin: 48px 0;
}
.home-gallery ul li {
    list-style-type: circle;
    margin-left: 60px;
}
@media only screen and (min-width: 48em) {
    .watermark svg {
        width: 196px;
    }
    .signature {
        margin: 80px;
        font-size: 64px;
        padding-bottom: 20%;
    }
    .message p {
        font-size: 22px;
    }
    .banner2 .content h2 {
        font-size: 40px;
    }
    .home .banner1 {
        background-position: center 80%;
    }
    .home .banner2 {
        background-position: 70% center;
    }
}

@media only screen and (min-width: 78rem) {
    .home .banner {
        margin: 0;
        width: 100%;
    }
    .home .content {
        padding: 0;
    }
    .home .banner1 {
        background-image: url('../assets/images/home-banner.jpg');
        background-position: center;
    }
    .home .banner2 {
        background-image: url('../assets/images/home-banner2.jpg');
        background-position: 70% center;
    }

    .banner2 .content h2 {
        font-size: 48px;
    }
    .watermark svg {
        width: 248px;
    }
    .message p {
        font-size: 32px;
    }
    .signature {
        padding-bottom: 5%;
    }
}
