.section-2fr {
    display: grid;
    display: -ms-grid;
    -ms-grid-rows: auto 1fr auto;
    grid-template-rows: 1fr auto;
    -ms-grid-columns: minmax(0, 1fr);
    /* grid-template-columns: minmax(0, 1fr); */
}

.section-3fr {
    min-height: 100vh;
    display: grid;
    display: -ms-grid;
    -ms-grid-rows: auto 1fr 1fr auto;
    grid-template-rows: 1fr 1fr auto;
    -ms-grid-columns: minmax(0, 0, 1fr);
    /* grid-template-columns: minmax(0, 1fr); */
}
.app > div {
    padding: 0 20px;
}
.footer {
    background-image: url('./assets/images/footer_city_white.png');
    background-size: cover;
    box-shadow: inset 0 0 0 1000px #1c1c1cc4;
    background-repeat: no-repeat;
    background-position: 8% bottom;
    min-height: 369px;
}
.footer .rights-reserved {
    position: absolute;
    left: 50%;
    top: 75%;
    color: #fff;
    transform: translate(-50%, -75%);
    font-size: 18px;
    text-align: center;
    line-height: 26px;
    min-width: 320px;
}
.footer .footer-content {
    text-align: center;
    border-top: 1px solid var(--border-color-primary);
    width: 100%;
    max-width: 78rem;
    margin: 0 auto;
    padding: 20px 16px 80px 16px;
}
.footer img {
    width: 100%;
}
.error input {
    border: 2px solid var(--border-color-error);
}
.is-relative {
    position: relative;
}
.hide {
    display: none !important;
}
.btn {
    padding: 6px 12px;
    font-size: 14px;
    height: 36px;
    cursor: pointer;
    -webkit-transition: all 0.25s ease-out;
    -o-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
    width: 100%;
    border-radius: var(--border-radius);
    border: 0;
}
.btn[disabled] {
    background-color: #6e273e !important;
    border-color: #6e273e !important;
}
.btn.neutral {
    border: 1px solid var(--border-color-primary);
    background-color: var(--bg-color-primary);
    color: var(--text-color-invert);
}

.btn.brand {
    background-color: var(--bg-color-brand);
    border: 1px solid var(--border-color-brand);
    color: var(--text-color-primary);
}

.btn.neutral:not([disabled]):hover {
    background-color: var(--bg-color-hover-neutral);
}

.btn.brand:not([disabled]):hover {
    background-color: var(--bg-color-hover-brand);
}
.btn-icon {
    padding-left: 12px;
}
.btn-loader {
    display: inline-block;
    margin-left: 8px;
    height: 14px;
    width: 14px;
}
button span {
    font-family: 'torus', sans-serif;
}

.bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
@-webkit-keyframes bounce {
    0%, 20%, 50%, 80%, 100% { -webkit-transform: translateY(0); }
    40% { -webkit-transform: translateY(-30px); }
    60% { -webkit-transform: translateY(-15px); }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
    40% { transform: translateY(-30px); }
    60% { transform: translateY(-15px); }
}
@keyframes spinner {
    to { transform: rotate(360deg); }
}

.spinner::before {
    content: '';
    position: absolute;
    border-radius: 50%;
    border: 1px solid var(--text-color-primary);
    border-top-color: var(--text-color-invert);
    animation: spinner .6s linear infinite;
    left: 0;
}
.spinner.neutral {
    border-top-color: var(--text-color-brand);
}
.spinner:before {
    width: 18px;
    height: 18px;
}
.spinner.xs:before {
    width: 14px;
    height: 14px;
}
.active .clip {
    animation: 250ms draw forwards linear;
}

.active .clip.p {
    animation: 100ms draw forwards linear;
    animation-delay: 3.5s;
}

.clip.j1 {
    stroke-dasharray: 284;
    stroke-dashoffset: 284;
}

.clip.j2 {
    stroke-dasharray: 388;
    stroke-dashoffset: 388;
    animation-delay: 250ms;
}

.clip.a1 {
    stroke-dasharray: 95;
    stroke-dashoffset: 95;
    animation-delay: 500ms;
}

.clip.a2 {
    stroke-dasharray: 124;
    stroke-dashoffset: 124;
    animation-delay: 750ms;
}

.clip.y1 {
    stroke-dasharray: 165;
    stroke-dashoffset: 165;
    animation-delay: 1s;
}

.clip.y2 {
    stroke-dasharray: 202;
    stroke-dashoffset: 202;
    animation-delay: 1.25s;
}

.clip.s1 {
    stroke-dasharray: 90;
    stroke-dashoffset: 90;
    animation-delay: 1.5s;
}

.clip.s2 {
    stroke-dasharray: 128;
    stroke-dashoffset: 128;
    animation-delay: 1.75s;
}

.clip.on1 {
    stroke-dasharray: 94;
    stroke-dashoffset: 94;
    animation-delay: 2s;
}

.clip.on2 {
    stroke-dasharray: 250;
    stroke-dashoffset: 250;
    animation-delay: 2.25s;
}

.clip.f1 {
    stroke-dasharray: 263;
    stroke-dashoffset: 263;
    animation-delay: 2.5s;
}

.clip.f2 {
    stroke-dasharray: 375;
    stroke-dashoffset: 375;
    animation-delay: 2.75s;
}

.clip.f3 {
    stroke-dasharray: 124;
    stroke-dashoffset: 124;
    animation-delay: 3s;
}

.clip.p {
    stroke-dasharray: 409;
    stroke-dashoffset: 409;
}


@keyframes draw {
    100% {
        stroke-dashoffset: 0;
    }
}

.gallery {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.gallery-item {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 566px;
    margin: 40px auto;
    cursor: pointer;
}

.gallery-item img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 250ms ease-in-out;
}
.gallery-item h1 {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%);
    margin: 0;
    font-weight: 500;
    z-index: 2;
}
.gallery-item::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 26%);
    z-index: 1;
}
.gallery-item:hover img {
    transform: scale(1.2);
}
.gallery-carousel {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    animation: fadeout 180ms linear forwards;
    transition: opacity 150ms ease-in-out;
    opacity: 0;
    z-index: -1
}
.gallery-carousel.active {
    animation: fadein 150ms linear forwards;
    opacity: 1;
}
@keyframes fadein {
    0% {
        transform: scale(0.4);
    }
    50% {
        transform: scale(0.8);
    }
    100% {
        z-index: 3;
        transform: scale(1);
    }
}
@keyframes fadeout {
    0% {
        z-index: 3;
    }
    100% {
        z-index: -1;
        transform: scale(0.2);
    }
}
.swiper {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.swiper-view {
    height: 75%;
    width: 100%;
}

.swiper-thumbs {
    height: 25%;
    padding: 10px 0;
    max-width: 1920px;
}

.swiper-thumbs .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
    padding: 10px 10px 10px 0;
}
.swiper-thumbs .swiper-slide:last-child {
    padding-right: 0;
}
.swiper-thumbs .swiper-slide.swiper-slide-thumb-active {
    opacity: 1;
}
.swiper-view .swiper-slide img {
    object-fit: contain;
}
.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.swiper-action {
    position: absolute;
    top: 24px;
    right: 8px;
    opacity: 0.9;
    cursor: pointer;
    z-index: 3;
}
.swiper-action div {
    display: inline-block;
    width: 48px;
    text-align: center;
}
.swiper-background {
    background-color: #1c1c1c;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.icon-shutter {
    vertical-align: middle;
    width: 16px;
}
.image-story {
    position: absolute;
    bottom: 0;
    background: linear-gradient(0deg, rgb(0 0 0) 5%,  rgb(0 0 0 / 75%) 35%,  rgb(0 0 0 / 35%) 70%, rgb(0 0 0 / 0%) 100%);
    width: 100%;
    overflow: hidden;
    transition: max-height 150ms linear;
    max-height: 0;
    padding: 0 24px;
    left: 50%;
    transform: translate(-50%);
}
.gallery-carousel.active .image-story.active {
    max-height: 600px;
}
.story-content {
    position: relative;
    padding: 0px 0px 40px 8px;
    /* padding: 12px 12px 80px 12px; */
}
.story-content-close {
    position: absolute;
    bottom: 24px;
    left: 50%;
    cursor: pointer;
    transform: translate(-50%);
}
.story-content h1, .story-content h2 {
    margin: 0;
    font-size: 22px;
    font-weight: 400;
    text-align: left;
}
.story-content h2 {
    font-size: 18px;
    margin-bottom: 12px;
}
.swiper-pagination-bullet-active {
    --swiper-pagination-color: var(--bg-color-brand);
}
.photo-of-week {
    text-align: center;
}
.photo-of-week img {
    margin: 0 auto;
    max-width: 100%;
}
.photo-of-week h2, .photo-of-week h3 {
    font-size: 28px;
    font-weight: 400;
}
.photo-of-week h3 {
    font-size: 20px;
    font-weight: 100;
    margin: 4px 0;
}
.photo-of-week_title, .photo-of-week_meta {
    margin-bottom: 24px;
}
.photo-of-week_meta {
    margin-top: 24px;
}
.photo-of-week .location, .photo-of-week .specs {
    font-size: 16px;
}
.photo-of-week .specs svg {
    width: 16px;
    height: 16px;
}

@media only screen and (min-width: 48em) {
    .btn {
        padding: 8px 16px;
        font-size: 16px;
        height: 44px;
    }
    .btn-send {
        max-width: 100px;
        margin-left: auto;
    }
    .avatar-mob {
        display: none;
    }
    .story-content {
        position: relative;
        padding: 24px 24px 60px 24px;
        /* padding: 24px 24px 80px 24px; */
    }
    .story-content h1 {
        font-size: 28px;
        text-align: left;
    }
    .story-content h2 {
        font-size: 22px;
    }
    .icon-shutter {
        width: 20px;
    }
}
@media only screen and (min-width: 78rem) {
    .app > div {
        padding: 0;
    }
    .footer {
        background-position: bottom;
        background-size: contain;
    }
}