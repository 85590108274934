.contact {
    display: flex;
    margin: auto;
    max-width: 960px;
    width: 100%;
    color: var(--text-color-primary);
}
.contact .form {
    width: 100%;
}
.contact .avatar {
    display: none;
    width: 40%;
    padding-left: 40px;
    margin-bottom: 56px;
}
.contact .avatar > h2 {
    margin-bottom: 32px;
}
.contact .avatar img {
    width: 70%;
}
.contact input, .btn-send {
    margin: 12px 0;
}
.contact .inputs > :last-child {
    margin-bottom: 18px;
}
.contact .avatar-mob {
    display: block;
    max-height: 60px;
}
.contact .inputs-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: 62px;
    margin: 20px 0;
}
.contact .form-instructions {
    display: flex;
    flex-direction: column-reverse;
    border-bottom: 1px solid var(--border-color-primary);
    font-weight: 100;
    padding-bottom: 20px;
    margin-bottom: 20px;
}
.contact .form-instructions > div:first-child {
    width: 100%;
    padding-top: 20px;
}
.contact .form-instructions > div:last-child {
    text-align: right;
}
.form-instructions svg {
    padding-left: 10px;
    font-size: 16px;
}
@media only screen and (min-width: 48em) {
    .btn-send {
        max-width: 100px;
        margin-left: auto;
    }
    .contact .form-instructions {
        justify-content: space-between;
        flex-direction: row;
    }
    .contact .form-instructions > div:first-child {
        width: 52%;
        padding-top: 0;
    }
    .contact .avatar {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .contact .avatar-mob {
        display: none;
    }
    .contact .form {
        width: 60%;
    }
}


@media only screen and (min-width: 78rem) {}