:root {
    --page-pad-large: 100px;
    --page-pad-medium: 70px;
    --page-pad-small: 20px;

    --content-pad-large: 70px;
    --content-pad-medium: 60px;
    --content-pad-small: 20px;
    --content-pad-xsmall: 12px;

    /** TEXT COLOR **/
    --text-color-primary: #fff;
    --text-color-invert: #3A3D44;
    --text-color-brand: #92002f;
    --text-color-error: #b32626;

    /** BACKGROUND **/
    --bg-color-primary: #fff;
    --bg-color-brand: #87002b;
    --bg-color-hover-brand: #7d0028;
    --bg-color-hover-neutral: #f0f0f0;

    /** BORDERS **/
    --border-radius: 8px;
    --border-radius-input: 4px;
    --border-color-primary: #fff;
    --border-color-brand: #7d0028;
    --border-color-error: #db5151;

    /** TEXT SIZE **/
    --text-title-size-large: 60px;
    --text-title-lineheight-size-large: 68px;
    --text-subtitle-size-large: 32px;
    --text-p-size-large: 20px;

    --text-title-size-medium: 48px;
    --text-title-lineheight-size-medium: 56px;
    --text-subtitle-size-medium: 24px;
    --text-p-size-medium: 18px;

    --text-title-size-small: 30px;
    --text-title-lineheight-size-small: 38px;
    --text-subtitle-size-small: 20px;
    --text-p-size-small: 16px;
}
body {
    margin: 0;
    font-size: var(--text-p-size-small);
    background-color: #1c1c1c;
    color: #000;
    font-family: "torus", sans-serif, "Segoe UI", "Roboto";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
body::-webkit-scrollbar {
    width: 10px;
}

body::-webkit-scrollbar-track {
    background: transparent;
}

body::-webkit-scrollbar-thumb {
    background-color: #4e4e4e;
    border-radius: 4px;
    border: 0;
}
html, body, section, #root, .app {
    height: 100%;
}
* {
    box-sizing: border-box;
}
ul, p {
    margin: 0;
}
ul {
    padding-left: 0;
}
li {
    list-style: none;
}
h1 {
    margin: 0;
    font-weight: 100;
    font-family: "torus", 'salesforce', sans-serif, "Segoe UI", "Roboto";
    font-size: 48px;
    color: var(--text-color-primary);
    text-align: center;
    margin: 60px 0;
}
h2 {
    font-size: 24px;
    font-weight: 500;
    font-weight: 100;
    margin: 0;
}
label {
    display: block;
    color: var(--text-color-primary);
    padding-bottom: 3px;
}

input,
textarea,
select {
    height: 32px;
    border-radius: var(--border-radius-input);
    border: 1px solid rgb(221, 219, 218);
    padding: 0 14px;
    width: 100%;
}

textarea {
    height: unset;
    padding: 14px;
}

textarea:focus,
input:focus,
select:focus {
    outline: none;
}

a {
    text-decoration: none;
    color: var(--text-color-brand);
}

a:hover {
    color: var(--text-color-primary);
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

@font-face {
    font-family: "torus";
    font-weight: 700;
    src: url("./assets/fonts/torus-bold.woff2") format("woff2");
}
@font-face {
    font-family: "torus";
    font-weight: 600;
    src: url("./assets/fonts/torus-semibold.woff2") format("woff2");
}
@font-face {
    font-family: "torus";
    font-weight: 900;
    src: url("./assets/fonts/torus-heavy.woff2") format("woff2");
}
@font-face {
    font-family: "torus";
    font-style: italic;
    src: url("./assets/fonts/torus-italic.woff") format("woff");
}
@font-face {
    font-family: "torus";
    font-weight: 400;
    src: url("./assets/fonts/torus-light.woff2") format("woff2");
}
@font-face {
    font-family: "torus";
    font-weight: 100;
    src: url("./assets/fonts/torus-thin.woff2") format("woff2");
}
@font-face {
    font-family: "torus";
    font-weight: 500;
    src: url("./assets/fonts/torus-regular.woff2") format("woff2");
}
@font-face {
    font-family: "salesforce";
    font-weight: 100;
    src: url("./assets/fonts/salesforce-thin.woff") format("woff");
}
@font-face {
    font-family: "salesforce";
    font-weight: 300;
    src: url("./assets/fonts/salesforce-light.woff") format("woff");
}
.coming-soon {
    padding-top: 160px;
    color: var(--text-color-brand);
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    width: 100%;
    margin-left: -18px;
}

@media only screen and (min-width: 48em) {
    body {
        font-size: var(--text-p-size-medium);
    }
}

@media only screen and (min-width: 78rem) {
    body {
        font-size: var(--text-p-size-large);
    }
}
